.App {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background:#181015 url(./img/bg_header.jpg) no-repeat;
  height: calc(100vh);
  background-size: cover;
  background-position: bottom;
  color:white;
  height: 100vh;
}
html{
  background:#181015 url(./img/bg_header.jpg);
  background-size: cover;
  color:white;
  height: 100vh;
}
h1, h2, h3, h4, h5, h6{
  margin: .75rem !important;
}
div.card{
  background-color: transparent;
}
p{
  padding: 1rem;
}
.twitch-player {
  display: inline-block;
  text-align: center;
  width: -webkit-fill-available;

}
.service-div{
  max-height: 75vh;
}
.card-body {
     min-height: 1px;
     padding: 0.75rem !important;
}
#twitch-player{
  width:inherit;
  height: inherit;
  width: 100vh;
  height: 75vh;
}
img.service-img{
  max-width: 5%;
  max-height: 5%;
}
